import {motion} from 'framer-motion'
import styled, {css} from 'styled-components'

export const Container = styled(motion.div)`
  ${({theme}) => css`
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 9.6rem;

    padding-bottom: 6.4rem;

    @media (max-width: ${theme.breakpoints.small}) {
      gap: 1.6rem;
    }
  `}
`

export const ScrollIndicatorWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
`

export const Wrapper = styled.div`
  ${({theme}) => css`
    max-width: 134.5rem;
    width: 100%;
    margin: 0 auto;

    h1 {
      max-width: 100rem;
    }

    @media (max-width: ${theme.breakpoints.xxlarge}) {
      padding: 0 6.4rem;
    }

    @media (max-width: ${theme.breakpoints.medium}) {
      padding: 0 3.2rem;
    }

    @media (max-width: ${theme.breakpoints.small}) {
      padding: 0 2.4rem;
    }
  `}
`

export const BackToTopButton = styled(motion.button)`
  position: fixed;
  bottom: 2.4rem;
  right: 2.4rem;
  width: 2.4rem;
  background-color: transparent;
  border: none;
  transition: all 0.2s ease-in-out;

  img {
    width: 100%;
  }
`

export const SubtitleWrapper = styled.div`
  max-width: 100rem;
`
