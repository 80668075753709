import {memo, useCallback, useEffect, useState} from 'react'
import {Tooltip} from '@radix-ui/themes'
import {Contact, Content, Footer, Header, Heading, ScrollIndicator, Seo, Text,} from '@/components'
import Image from 'next/image'

import type {IAppProps} from './interfaces'
import * as S from './styles'

const scrollVariants = {
  initial: {y: '.5rem', opacity: 0},
  animate: {
    y: '0rem',
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {y: 400, opacity: 0},
}

function App({
               children,
               page,
               seo,
               footer = {show: true, showSocialButtons: true},
               showContact = true,
               showScrollIndicator = false,
             }: IAppProps) {
  const [showBackTopButton, setShowBackTopButton] = useState(false)

  const toggleVisible = useCallback(() => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setShowBackTopButton(true)
    } else if (scrolled <= 300) {
      setShowBackTopButton(false)
    }
  }, [])

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', toggleVisible)
    }
  }, [toggleVisible])

  return (
    <S.Container>
      <Seo {...seo} />

      <Header/>

      <S.Wrapper>
        {page?.title && <Heading bold>{page.title}</Heading>}

        <Content hasMarginTop={!!page?.title}>
          {page?.subtitle && (
            <S.SubtitleWrapper>
              <Text size="medium">{page.subtitle}</Text>
            </S.SubtitleWrapper>
          )}

          {children}

          {showContact && <Contact/>}
        </Content>
      </S.Wrapper>

      {footer.show && <Footer showSocialButtons={footer.showSocialButtons}/>}

      {showBackTopButton && (
        <Tooltip content="Back to top">
          <S.BackToTopButton
            variants={scrollVariants}
            initial={{y: '1rem', opacity: 0}}
            animate={{y: 0, opacity: 1, transition: {duration: 0.1}}}
            exit={{y: 400, opacity: 0, transition: {duration: 0.1}}}
            whileHover={{
              y: '-1rem',
              transition: {duration: 0},
            }}
            whileTap={{scale: 1}}
            onClick={scrollToTop}
          >
            <Image
              src="/assets/images/emojis/pointing-up.png"
              width={24}
              height={24}
              quality={1}
              alt=""
            />
          </S.BackToTopButton>
        </Tooltip>
      )}

      {showScrollIndicator && (
        <S.ScrollIndicatorWrapper>
          <ScrollIndicator/>
        </S.ScrollIndicatorWrapper>
      )}
    </S.Container>
  )
}

export default memo(App)
