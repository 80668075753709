import {Tooltip} from '@radix-ui/themes'
import {Button, Content, Heading, Notification, SocialIcon, Text,} from '@/components'
import {externalLinks} from '@/constants/externalLinks'
import {articles, socialLinks} from '@/data'
import {useAnalytics} from '@/hooks'
import {App} from '@/layout'
import type {IArticleModel} from '@/models'
import Image from 'next/image'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {sendOpenArticleEvent} from '@/pages/articles/utils'

import * as S from './styles'

export default function HomePage() {
  const router = useRouter()
  const {trackEvent} = useAnalytics()

  const handleOpenArticle = (article: IArticleModel) => {
    sendOpenArticleEvent(article, trackEvent)

    if (article.link.includes('medium')) {
      return window.open(article.link, '_blank')
    }

    return router.push(article.link)
  }

  return (
    <App footer={{showSocialButtons: false}} showContact={false}>
      <S.Container>
        <S.ImageWrapper onClick={() => router.push('/about')}>
          <Image
            width={120}
            height={120}
            src="/assets/images/home/me.jpeg"
            alt="Vinnicius Gomes"
          />
        </S.ImageWrapper>
        <Heading bold>
          I&apos;m Vinnicius — a software engineer, frontend specialist and
          mountain biker.
        </Heading>

        <S.NotificationWrapper>
          <Notification
            badgeText="New article"
            text={articles[0].title}
            onClick={() => handleOpenArticle(articles[0])}
          />
        </S.NotificationWrapper>

        <S.SocialWrapper>
          {socialLinks.map(item => (
            <Tooltip key={item.id} content={`Open ${item.name}`}>
              <S.SocialItem
                href={item.link}
                target="_blank"
                aria-label={item.icon}
                whileTap={{
                  scale: 0.9,
                }}
              >
                <SocialIcon icon={item.icon}/>
              </S.SocialItem>
            </Tooltip>
          ))}
        </S.SocialWrapper>

        <Content>
          <S.Section>
            <S.DescriptionWrapper>
              <Text size="medium">
                I&apos;m currently working as a Senior Software Engineer at{' '}
                <Tooltip content="Open Stone website">
                  <a
                    href={externalLinks.current_job_company}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stone
                  </a>
                </Tooltip>
                . Based in São José dos Campos, Brazil, I&apos;ve been
                dedicating myself to contributing to the community by sharing my
                knowledge through{' '}
                <Tooltip content="Open articles page">
                  <Link href="/articles">articles</Link>
                </Tooltip>
                .
              </Text>
              <Text size="medium">
                I started in the developing world in 2012, learning to program
                by researching the internet. At only 12 years old, I developed{' '}
                <Tooltip content="Open my first web website">
                  <a
                    href={externalLinks.first_web_site}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    my first website
                  </a>
                </Tooltip>
                , since then, I never stopped. In 2017, I started my
                bachelor&apos;s degree in computer science, and I fell in love
                even more with the area.
              </Text>
              <Text size="medium">
                My personal mission is to push the world forward by creating a
                bridge between people and technology.
              </Text>

              <S.ButtonWrapper>
                <Button
                  onClick={() => {
                    trackEvent('home.click.about_me_button')
                    router.push('/about')
                  }}
                  data-testid="about-me-button"
                >
                  Read more about me
                </Button>
                <Text size="medium">
                  <Tooltip content="Open my resume">
                    <Link
                      href="/resume"
                      data-testid="resume-button"
                      onClick={() => trackEvent('home.click.resume_button')}
                    >
                      Visit my resume
                    </Link>
                  </Tooltip>
                </Text>
              </S.ButtonWrapper>
            </S.DescriptionWrapper>
          </S.Section>
        </Content>
      </S.Container>
    </App>
  )
}
