import type {IArticleModel} from '@/models'

export function sendOpenArticleEvent(
  data: IArticleModel,
  sender: (arg0: string, eventProperties?: Record<string, any>) => void,
) {
  sender('open.article', {
    article: data,
  })
}
